import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedPage from './components/ProtectedPage';
import HomePage from './screens/Home';
import LoginPage from './screens/LoginPage';
import { BusinessesPage } from './screens/business/BusinessesPage';
import { RegisteredBusinessPage } from './screens/business/RegisteredBusinessPage';
import { CurrentRegisteredShopContextProvider } from './storage/context/RegisteredShopsProvider';
import CurrentUnregisteredShopContextProvider from './storage/context/UnregisteredShopsProvider';
import { UnregisteredBusinessPage } from './screens/business/UnregisteredBusinessPage';
import { PlansPage } from './screens/plans/PlansPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import BillingPage from './screens/billing/BillingPage';
import MoMoUnpaidBillingPage from './screens/billing/UnpaidMoMoBillingPage';

const queryClient = new QueryClient(
    {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                staleTime: 1000 * 60 * 5, // 5 minutes
            },
        },
    }
);

export default function App() {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <CurrentUnregisteredShopContextProvider>
                    <Router>
                        <Routes>
                            <Route
                                index
                                element={
                                    <ProtectedPage
                                        page={<HomePage />}
                                    />
                                }
                            />
                            <Route
                                path="login"
                                element={<LoginPage />}
                            />
                            <Route
                                path="businesses"
                                element={
                                    <ProtectedPage
                                        page={<BusinessesPage />}
                                    />
                                }
                            />
                            <Route
                                path='/billing'
                                element={
                                    <ProtectedPage
                                        page={<BillingPage />}
                                    />
                                }
                            />
                            <Route
                                path="/billing/pending-momo"
                                element={
                                    <ProtectedPage
                                        page={<MoMoUnpaidBillingPage />}
                                    />
                                }
                            />
                            <Route
                                path='/plans'
                                element={
                                    <ProtectedPage
                                        page={<PlansPage />}
                                    />
                                }
                            />
                            <Route
                                path='/registered_business/:businessid'
                                element={
                                    <ProtectedPage
                                        page={
                                            <CurrentRegisteredShopContextProvider>
                                                <RegisteredBusinessPage />
                                            </CurrentRegisteredShopContextProvider>}
                                    />
                                }
                            />
                            <Route
                                path='/unregistered_business/:submissionid'
                                element={
                                    <ProtectedPage
                                        page={
                                            <UnregisteredBusinessPage />
                                        }
                                    />
                                }
                            />
                            <Route path="*" element={<div>Not Found</div>} />
                        </Routes>
                    </Router>
                </CurrentUnregisteredShopContextProvider>
            </QueryClientProvider>
        </div>
    );
}
