import { Container, Menu } from 'semantic-ui-react';
import { appName } from '../common/constant';

interface DefaultHeaderProps {
    mobile?: boolean;
}

export function DefaultHeader({ mobile }: DefaultHeaderProps) {

    if (mobile) {
        return (
            <>
                <Menu.Item as='a' href='/businesses'>Business</Menu.Item>
                <Menu.Item as='a' href='/billing'>Billing</Menu.Item>
                <Menu.Item as='a' href='/billing/pending-momo'>MoMo</Menu.Item>
                <Menu.Item as='a' href='/plans'>Plans</Menu.Item>
            </>
        );
    }

    return (
        <Menu inverted style={{ borderRadius: 0 }} size='large'>
            <Container>
                <Menu.Item as='a' header href="/">
                    {appName}
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item as='a' href='/businesses'>Businessess</Menu.Item>
                    <Menu.Item as='a' href='/billing'>Billing</Menu.Item>
                    <Menu.Item as='a' href='/billing/pending-momo'>MoMo</Menu.Item>
                    <Menu.Item as='a' href='/plans'>Plans</Menu.Item>
                </Menu.Menu>
            </Container>
        </Menu>
    );
}