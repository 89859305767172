import { useQuery } from "@tanstack/react-query";
import { DefaultResponsiveContainer } from "../../components/DefaultResponsiveContainer";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { confirmPayment, getAllMoMoPendingInvoices } from "../../api/subscription-api";
import { Button, Container, Loader, Table } from "semantic-ui-react";
import { ConfirmationPopup } from "../../components/ConfirmationPopup";
import { useCallback, useState } from "react";


function PendingPaymentRow({ invoice, onConfirm }: { invoice: any, onConfirm: () => void }) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: "currency",
        currency: invoice.currency,
    });

    return (
        <Table.Row>
            <Table.Cell>{invoice.business_id}</Table.Cell>
            <Table.Cell>
                {formatter.format(invoice.amount)}
            </Table.Cell>
            <Table.Cell>{invoice.issue_date}</Table.Cell>
            <Table.Cell>{invoice.created_at}</Table.Cell>
            <Table.Cell>{invoice.reference_id}</Table.Cell>
            <Table.Cell>{invoice.status}</Table.Cell>
            <Table.Cell>
                <Button
                    primary
                    compact
                    onClick={onConfirm}
                >
                    Confirm Payment
                </Button>
            </Table.Cell>
        </Table.Row>
    );
}

function UnpaidPayments() {
    const { currentUser } = useGeneralContext();
    const {
        data,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['momo-pending-payments'],
        queryFn: async () => {
            if (!currentUser) {
                return [];
            }
            const idToken = await currentUser.getIdToken();
            return getAllMoMoPendingInvoices(idToken);
        },
        enabled: !!currentUser,
    });

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState({
        invoice_id: "",
        transaction_id: "",
    });
    const [confirmingPayment, setConfirmingPayment] = useState(false);

    const handleConfirm = useCallback(async () => {
        // TODO: Check why there's no impact on the UI
        setConfirmingPayment(() => true);
        try {
            if (!currentUser) {
                return;
            }
            const idToken = await currentUser.getIdToken();
            if (!idToken || !selectedInvoice.invoice_id) {
                return;
            }
            await confirmPayment(selectedInvoice.invoice_id, idToken);
            refetch();
            setIsPopupOpen(() => false);
            setSelectedInvoice({
                invoice_id: "",
                transaction_id: "",
            });
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmingPayment(() => false);
        }
    }, [currentUser, selectedInvoice, refetch, setConfirmingPayment]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!data) {
        return (
            <div>
                <p>No pending payments</p>
            </div>
        );
    }

    return (
        <Container
            style={{
                marginTop: '1em',
                padding: '1em',
                width: '100%',
            }}
        >
            <Loader active={confirmingPayment} size="huge" />
            <ConfirmationPopup
                open={isPopupOpen && !confirmingPayment}
                header={`Confirm Transaction ID: ${selectedInvoice.transaction_id}`}
                text="Are you sure you want to confirm this MoMo payment?"
                onCancel={async () => {
                    setIsPopupOpen(false);
                    setSelectedInvoice({
                        invoice_id: "",
                        transaction_id: "",
                    });
                }}
                onConfirm={handleConfirm}
            />
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Business ID</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Issued Date</Table.HeaderCell>
                        <Table.HeaderCell>Payment Initiated</Table.HeaderCell>
                        <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((invoice: any) => (
                        <PendingPaymentRow
                            key={invoice.invoice_id}
                            invoice={invoice}
                            onConfirm={() => {
                                setSelectedInvoice({
                                    invoice_id: invoice.invoice_id,
                                    transaction_id: invoice.reference_id,
                                });
                                setIsPopupOpen(true);
                            }}
                        />
                    ))}
                </Table.Body>
            </Table>
        </Container>
    );
}

export default function MoMoUnpaidBillingPage() {
    return (
        <DefaultResponsiveContainer>
            <div>
                <h1>MoMo Pending Payments</h1>
                <UnpaidPayments />
            </div>
        </DefaultResponsiveContainer>
    );
}