import { Button, Dropdown, Header, Input, Segment, Table } from "semantic-ui-react";
import { DefaultResponsiveContainer } from "../../components/DefaultResponsiveContainer";
import { useState } from "react";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { getAllInvoices } from "../../api/subscription-api";
import { useQuery } from "@tanstack/react-query";

const statusOptions = [
    { key: "all", text: "All", value: "all" },
    { key: "paid", text: "Paid", value: "paid" },
    { key: "issued", text: "Pending", value: "issued" },
];

function InvoicesDisplay() {
    const { currentUser } = useGeneralContext();
    const [statusFilter, setStatusFilter] = useState("all");
    const [businessIdFilter, setBusinessIdFilter] = useState("");
    const [nbToDisplay, setNbToDisplay] = useState(10);

    const { data, isLoading } = useQuery({
        queryKey: ["invoices"],
        queryFn: async () => {
            if (!currentUser) return [];
            const idToken = await currentUser.getIdToken();
            return await getAllInvoices(idToken);
        },
        enabled: !!currentUser,
    });

    if (isLoading) {
        return <p>Loading...</p>;
    }

    const filteredData = data.filter((invoice: any) => {
        return (
            (statusFilter === "all" || invoice.status === statusFilter) &&
            (businessIdFilter === "" || invoice.business_id.includes(businessIdFilter))
        );
    });

    const displayedData = filteredData.slice(0, nbToDisplay);

    return (
        <Segment>
            <Header as="h2">All Invoices</Header>
            <p>
                This page displays all invoices that have been issued to businesses. You can filter
                the invoices by status and business ID.
            </p>
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                <Dropdown
                    placeholder="Filter by Status"
                    selection
                    options={statusOptions}
                    value={statusFilter}
                    onChange={(e, { value }) => setStatusFilter(value as any)}
                />
                <Input
                    placeholder="Filter by Business ID"
                    value={businessIdFilter}
                    onChange={(e) => setBusinessIdFilter(e.target.value)}
                />
            </div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Invoice ID</Table.HeaderCell>
                        <Table.HeaderCell>Business ID</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Due Date</Table.HeaderCell>
                        <Table.HeaderCell>Created At</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {displayedData.map((invoice: any) => (
                        <Table.Row key={invoice.invoice_id}>
                            <Table.Cell>{invoice.invoice_id}</Table.Cell>
                            <Table.Cell
                            >
                                <a
                                    href={`/registered_business/${invoice.business_id}`}
                                    style={{ marginLeft: "10px" }}
                                >
                                    {invoice.business_id}
                                </a>
                            </Table.Cell>
                            <Table.Cell>{invoice.status}</Table.Cell>
                            <Table.Cell>{invoice.amount}</Table.Cell>
                            <Table.Cell>{invoice.due_date}</Table.Cell>
                            <Table.Cell>{invoice.issue_date}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="2">
                            <p>Displaying {displayedData.length} of {filteredData.length} invoices</p>
                        </Table.HeaderCell>

                        <Table.HeaderCell colSpan="4">
                            <Button
                                content="Load More"
                                onClick={() => setNbToDisplay(nbToDisplay + 10)}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Segment>
    );
}


export default function BillingPage() {
    return (
        <DefaultResponsiveContainer>
            <Header as="h1">Billing</Header>
            <InvoicesDisplay />
            {/*<Button
                content="Sync With Firestore"
                color="red"
                onClick={onSubmitSync}
            />
            <p>Don't click this button unless you know what you are doing.</p>*/}
        </DefaultResponsiveContainer>
    );
}